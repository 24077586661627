<template>
  <div class="inbox">
    <ScrollNotice/>
    <el-container class="inbox container">
      <el-container>
        <el-aside width="275px">
          <div class="header" style="text-align: center">
            
			 
			<img :src="this.apiUrl+this.infos.pic"  style="width:100px"  >	
			
            <el-row class="text-sm">
              <el-col :span="12" class="text-right">
                1上次登陆时间：
              </el-col>
              <el-col :span="12" class="text-left">
                2020.11.10
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <h3 class="margin-left">{{this.infos.name}}</h3>
              </el-col>
              <el-col :span="12" class="text-left">
                <h3 class="text-light text-sm">(ID: {{this.infos.no}})</h3>
              </el-col>
            </el-row>

            <el-form class="text-sm" label-width="100px" label-suffix=":">
				
              <el-form-item label="技术满意度" v-if="type_user==2">
                <el-row>
                  <el-col :span="2">0</el-col>
                  <el-col :span="16" class="progress">
                    <el-progress :percentage="90" status="success" :show-text="false"></el-progress>
                  </el-col>
                  <el-col :span="6">4.5分</el-col>
                </el-row>
              </el-form-item>
			  
              <el-form-item label="文明满意度" v-if="type_user==2">
                <el-row>
                  <el-col :span="2">0</el-col>
                  <el-col :span="16" class="progress">
                    <el-progress :percentage="90" status="success" :show-text="false"></el-progress>
                  </el-col>
                  <el-col :span="6">4.5分</el-col>
                </el-row>
              </el-form-item>
			  
			  
			  
         <!--     <el-button type="primary">收件箱</el-button>
              <el-button type="primary">谁看过我</el-button> -->
			  
			  
            </el-form>
          </div>

          <h3 class="title margin-top-sm">服务中心</h3>
          <el-menu
              default-active="2"
              router>
            <el-menu-item v-for="(menu, index) in menus" :key="index" :index="menu.url">
              <i :class="menu.icon" v-if="menu.icon"></i>
              <span class="label">{{menu.name}}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
		
		
		
        <el-main class="body">
          <el-tabs type="border-card"    @tab-click="handleTabClick">
            <el-tab-pane label="未读信" name="0">
              <div class="param">
                <span>全部</span>
                <el-divider direction="vertical"></el-divider>
                <span>按时间</span>
              </div>   

              <div class="list" v-for="i in mess_all" :key="i">
                <el-row>
                  <el-col :span="4">
                   
					<img :src="i.img" style="width: 100px;"  >
                  </el-col>
				  
				  
                  <el-col :span="14">
                    <h3>{{i.name}}:{{i.content}}</h3>
                    <p class="text-grey desc">
                      <span>{{i.type}}</span>
					   <!-- <span>外科医生</span> -->
                    </p>
                    <div class="text-sm margin-top">
                      <i class="el-icon-time"></i> {{i.create_time}}
                    </div>
                  </el-col>
                  <el-col :span="4" style="line-height: 100px">
                  <!--  <span>{{i.look_num}}封未读</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>共{{i.num}}封</span> -->
                  </el-col>
                  <el-col :span="2" style="line-height: 100px;margin-left: 110px;;">
                    <el-button type="primary" @click="toChat(i.param_uid,i.id)" >查看</el-button>
                  </el-col>
                </el-row>
              </div> 
			  
			  
            </el-tab-pane>
			
			
			
            <el-tab-pane label="已读信" name="1">
              <div class="param">
                <span>全部</span>
                <el-divider direction="vertical"></el-divider>
                <span>按时间</span>
              </div>

              <div class="list" v-for="i in mess_all" :key="i">
                <el-row>
                  <el-col :span="4">
                    <el-image :src="i.img"  style="width: 100px; height: 70px;" fit="fit"></el-image>
                  </el-col>
                  <el-col :span="14">
                    <h3>{{i.name}}:{{i.content}}</h3>
                    <p class="text-grey desc">
						
						  <span>{{i.type}}</span>
                   <!--   <span>研究生</span> <span>外科医生</span> -->
                    </p>
                    <div class="text-sm margin-top">
                      <i class="el-icon-time"></i> {{i.create_time}}
                    </div>
                  </el-col>
                <!--  <el-col :span="4" style="line-height: 100px">
                    <span>3封未读</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>共10封</span>
                  </el-col>
                  <el-col :span="2" style="line-height: 100px">
                    <el-button type="primary">查看</el-button>
                  </el-col> -->
				  
				  <el-col :span="2" style="margin-left: 110px;line-height: 100px">
				    <el-button type="primary" @click="toChat(i.param_uid,i.id)" >查看</el-button>
				  </el-col>
                </el-row>
				
				 
					
              </div>
            </el-tab-pane>
			
            <el-tab-pane label="已发信" name="2">
              <div class="param">
                <span>全部</span>
                <el-divider direction="vertical"></el-divider>
                <span>按时间</span>
              </div>

              <div class="list" v-for="i in mess_all" :key="i">
                <el-row>
                  <el-col :span="4">
                    <el-image :src="i.img"  style="width: 100px; height: 70px;" fit="fit"></el-image>
                  </el-col>
                  <el-col :span="14">
                    <h3>{{i.name}}:{{i.content}}</h3>
                    <p class="text-grey desc">
                      <span>{{i.type}}</span>
                    </p>
                    <div class="text-sm margin-top">
                      <i class="el-icon-time"></i> {{i.create_time}}
                    </div>
                  </el-col>
                 <!-- <el-col :span="4" style="line-height: 100px">
                    <span>3封未读</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>共10封</span>
                  </el-col> -->
                  <el-col :span="2" style="margin-left: 110px;line-height: 100px">
				    <el-button type="primary" @click="toChat(i.param_uid,i.id)" >查看</el-button>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="站内信">
              <div class="param">
                <span>全部</span>
                <el-divider direction="vertical"></el-divider>
                <span>按时间</span>
              </div>

              <div class="list" v-for="i in 6" :key="i">
                <el-row>
                  <el-col :span="4">
                    <el-image :src="headImg" fit="fit"></el-image>
                  </el-col>
                  <el-col :span="14">
                    <h3>王医生</h3>
                    <p class="text-grey desc">
                      <span>研究生</span> <span>外科医生</span>
                    </p>
                    <div class="text-sm margin-top">
                      <i class="el-icon-time"></i> 2021-01-12 12:00
                    </div>
                  </el-col>
                  <el-col :span="4" style="line-height: 100px">
                    <span>3封未读</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>共10封</span>
                  </el-col>
                  <el-col :span="2" style="line-height: 100px">
                    <el-button type="primary">查看</el-button>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane> -->
			
			
			<!-- <el-pagination class="text-center"
							      background
							      layout="prev, pager, next, sizes, total"
							      :total="total"
							      :page-size="pageSize"
							      :current-page="currentPage"
							      :page-sizes="[10, 20, 30, 40]"
							      @current-change="handlePageChange"
							      @size-change="handleSizeChange"
							    >
							    </el-pagination> -->
								
								
								<el-pagination class="text-center"
								               background
								               layout="prev, pager, next"
											    @size-change="handleSizeChange"
											         @current-change="handleCurrentChange"
											         :current-page="currentPage"
								               :total="total">
								</el-pagination>
								
								
								
								 
								<!-- <el-pagination
								       @size-change="handleSizeChange"
								       @current-change="handleCurrentChange"
								       :current-page="currentPage"
								       :page-sizes="[10, 20, 30, 50]"
								       :page-size="pageSize"
								       layout="total, sizes, prev, pager, next, jumper"
								       :total="total">
								     </el-pagination>
									 -->
								
          </el-tabs>
		  

					
        </el-main>
      </el-container>

    </el-container>

  </div>

</template>

<script>
	
  import axios from "axios";
  import ScrollNotice from "../../components/ScrollNotice";

  import {mapState} from 'vuex';

  export default {
    name: "InboxIndex",
    components: {
      ScrollNotice
    },
    data() {
      return {
        headImg: require('../../assets/images/doctor.png'),
		
		mess:[],
		mess2:[],
		mess3:[],
		mess_all:[],
		status_now:0,
		
		infos:{},
		  type_user:0,
		   total: 100, // 总数据量
		        pageSize: 10, // 每页显示的数据量
		        currentPage: 1, // 当前页码
      }
    },
	created() { 
		this.type_user = sessionStorage.getItem('type');
		if(!sessionStorage.getItem('uid')){
			this.$router.replace('/login')
		} 	
		
		this.fetchData(0)
	 
		
				const params2 = new URLSearchParams();
				var query=this.$route.query; 
				this.id = query.id; 
				if(!sessionStorage.getItem('uid')){
					this.infos.pic = '/static/images/1639287252(1).jpg';
				}else{
					params2.append('uid', sessionStorage.getItem('uid')); 
					axios.post(this.apiUrl+'/api/user/getInfo',params2)
					.then((response) => {    
						this.infos =response.data.data;  
						
					}) 
					.catch(function(error) {
					  console.log(error);
					});
				}
		
	
 
		
		
		
			
	},
	
	methods: { 
	  // 每页条数改变
	     handleSizeChange(val) {
	       this.pageSize = val;
	       this.fetchData();
	     },
	     
	     // 当前页改变
	     handleCurrentChange(val) {
	       this.currentPage = val;
	       this.fetchData();
	     },
		handleTabClick(tab) {
			    //  this.currentTab = name;
				//alert(tab.name)
				
				this.total=100, // 总数据量
				    this.pageSize=10, // 每页显示的数据量
				     this.currentPage=1, // 当前页码
				this.status_now = tab.name;
			      this.fetchData( );
			    },

		fetchData() { 
						//--------------
						const params33 = new URLSearchParams();
						params33.append('uid', sessionStorage.getItem('uid'));
						params33.append('type', sessionStorage.getItem('type'));
						params33.append('page',this.currentPage);
						params33.append('size',  this.pageSize);
						 
								  
						params33.append('status', this.status_now);
						
						axios.post(this.apiUrl + '/api/user/getEmail', params33)
							.then((response) => {
								this.mess_all = response.data.data; 
								this.total = response.data.total; 
								
							})
							.catch(function(error) {
								console.log(error);
							});
							//--------------
							
							// this.$axios
							// 	.get('/index/index', {
							// 		params: {
							// 			page: this.currentPage,
							// 			page_size: this.pageSize,
							// 		},
							// 	})
							// 	.then((response) => {
							// 		const res = response.data;
							// 		if (res.code === 200) {
							// 			this.tableData = res.data; // 更新表格数据
							// 			this.total = res.total; // 更新总条数
							// 		}
							// 	})
							// 	.catch((error) => {
							// 		console.error('请求失败:', error);
							// 	});
					},

		toChat(did,id){
			
		  //更新已读状态
		  // const params = new URLSearchParams();
		  // params.append('id', id);  
		  // axios.post(this.apiUrl+'/api/user/setEmailStatus',params)
		  // .then((response) => {   
		  			  
		  // })
		  // .catch(function(error) {
		  //     console.log(error);
		  // }); 
		  
		//	alert(did)
		  this.$router.push({"name":"在线聊天", query:{did : did}})
		}
	},
	mounted() {
	  this.getHomeList(0);
	  this.getPaidList();
	},
	
    computed: {
      ...mapState(["menus"])
    }
  }
</script>

<style scoped lang="scss">
  .container {
    width: 1200px;
    margin: 20px auto;

    .el-header {
      padding: 0;


    }


    .el-aside {
      background: #ffffff;
      margin-right: 15px;

      .header {
        .el-image {
          width: 100px;
          height: 100px;
          border: 1px solid #E8E8E8;
          margin-top: 20px;
        }

        .text-sm {
          font-size: 12px;
          margin-top: 5px;

          & /deep/ * {
            font-size: 12px !important;
          }
        }

        h3 {
          margin: 0;
          padding: 5px 0;

          &.margin-left {
            margin-left: 30px;
          }
        }

        .text-light {
          font-weight: lighter;
        }

        .progress {
          padding-top: 13px;
        }

        .el-form {
          margin-bottom: 20px;

          .el-form-item {
            margin-bottom: 0px;
            padding-right: 10px;
          }

          .el-button {
            margin-top: 20px;
            width: 100px;
            border-radius: 5px !important;
          }
        }
      }

      .title {
        height: 58px;
        background: #535353;
        color: #ffffff;
        padding: 0px;
        margin: 0px;
        text-align: center;
        line-height: 58px;
        font-size: 22px;

        &.margin-top-sm {
          margin-top: 10px;
        }
      }

      .el-menu {
        .el-menu-item {
          height: 48px;
          line-height: 48px;
          border-bottom: 1px inset;

          /deep/ i:before {
            color: $green !important;
          }
        }

      }
    }

    .body {

      .margin-top {
        margin-top: 10px;
      }

      .el-tabs{
        min-height: 1200px;
      }

      .list {
        border-top: 1px solid #cccccc;
        margin: 10px;
        padding-top: 10px;

        .el-image {
          height: 100px;
        }

        .desc {
          span {
            margin-right: 20px;
          }
        }
      }

      .text-grey {
        color: #999999;
      }

      .text-sm {
        color: #999999;
        font-size: 12px;
      }

    }

  }
</style>
